.footer {
  height:10%;  
  margin-bottom: 0;
 
  background: var(--bg-black);
  text-align: center;
}
.footerLinks {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.footer h5{
  font-size:medium;
  display: inline-block;
  color: var(--text-mute);
}

.footerLinks a{
  margin: 2px 20px;
  margin-top: 10px;
  display: inline-block;
  text-decoration: none;
  color: var(--text-mute);
  position: relative;
}

.footerLinks a:hover {
  color: var(--white-hover);
}

.footerLinks a:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--blue);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.footerLinks a:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
  background-color: var(--purple) ;

}
