.about {
  width: 80%;
  margin: 50px auto;
  font-size: large;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about .header { 
  font-size:xx-large;
  color: var(--blue);
  margin-bottom: 5px;
  align-self: center;
}

.langsHeader, .toolsHeader, .findMe{
  font-weight: 600;
  font-size: larger;
  align-self: flex-start;

}

.main {
  display: flex;
  flex-direction: row;
}

.toolsHeader, .findMe {
  margin-top: 20px;
}

.langsHeader {
  color: var(--purple);
}

.toolsHeader{
  color: var(--green);
}

.findMe {
  color: var(--red);
}

img {
  height: 50px;
  width: auto;
  margin-left: 10px;
}

.skillsHeader {
  font-size:xx-large;
  color: var(--blue);
  margin-bottom: 5px;
  text-align: center;
}
.skillsHeader p {
  font-size:large;
  color:  var(--white);
  margin: 5px 30px;

}

.skill {
  display: flex;
  flex-direction: column;
  text-align: center;
  transition: 0.2s ease-in;
}

.skill span {
  font-size:x-small;
  margin-left: 10px;
}

.skill:hover {
  transform:scale(1.15);
  font-size:small;

}

.findMeIcons {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:center;

}

.images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;


}

.mainImg{
  width: 50%;
  height: auto;
  margin-top: -40px;
}



@media (max-width: 900px) {
  .main {
    flex-direction: column;
  }
  .mainImg {
    order: 3;
    width: 70%;
    margin: auto;
    margin-top: 20px ;
  }
  #about{
    order: 1;
  }
  .main p{
    order: 2;
  }
}

hr {
  width: 70%;
  margin: auto !important;
  margin-bottom: 20px !important;
}
