.main{
  display: flex;
}
.hello{
  height: 40rem;
  background-color: var(--bg-white);
  display: flex;
  justify-content: center;
  margin: auto;
}
.hello p{
  color: var(--bg-black);
  align-self: flex-start;
  padding: 0 40px;
  margin: auto;
  padding-bottom: 30px;
}

.Ghazal{
  font-size: xx-large;
  margin-bottom: 5px;
  color: var(--blue-dark);
}


.hello p > .student{
  margin-top: 0;
  font-size:large;
}

.hello img {
  height: 100%;
  width: auto;
  margin-left: 10px;
  margin-bottom: 15px;
}





.findMeIcons {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:flex-start;
}

.findMeIcons img{
  height: 50px;
  width: auto;
  margin-bottom: 0;

}
.skill {
  display: flex;
  flex-direction: column;
  text-align: center;
  transition: 0.2s ease-in;
}

.skill span {
  font-size:x-small;
  margin-left: 10px;
}

.skill:hover {
  transform:scale(1.15);
  font-size:small;

}

@media (max-width: 900px) {
  .hello {
    flex-direction: column;
  }
  .findMeIcons {
    justify-content: center;
  }
  .hello p {
    text-align: center;
    margin-top: 250px;
  }
  .mainImg{
    width: 50% !important;
    height: auto !important;
    margin: auto !important;
    margin-bottom: 150px !important;


  }
  
}


