.dividerWrapper{
  position: relative;
  margin-top: 2px;
  margin-bottom: -2px;
  margin-left: -1px;
  margin-right: -1px;


}

.divider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.divider svg {
  position: relative;
  display: block;
  width: calc(132% + 1.3px);
  height: 52px;
}

.shapeFill{
  fill: var(--bg-white);
}